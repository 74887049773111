import axios, { AxiosError } from 'axios'
import { LOGIN_ACCESS_TYPE, PATHS, SESSION_STATE } from 'lib/enum'

const instance = axios.create({
  baseURL: process.env.REACT_APP_PERXONA_BASE_API_URL,
})

export const loginService = async (username: string, password: string) => {
  try {
    const response = (
      await instance.post(PATHS.USER_PATH + PATHS.LOGIN_PATH, {
        username,
        password,
        accessType: LOGIN_ACCESS_TYPE.WEB,
        state: SESSION_STATE.ACTIVE,
      })
    ).data
    return response
  } catch (error) {
    throw error as AxiosError
  }
}
