//import aes from crypto-js/sha256.js
import { AES, enc, mode, pad } from 'crypto-js'

const ofuscate = () => {
  const part = 'C*F-JastuvwxQZNdRgUkXp2s5v8y/B?E(G+KbPeS' //6primero
  const part2 = 'NPdWREgAUIk' //saltando 1 letra
  const part3 = 'WpsC***FwJasXp2s5v' //6 ultimos
  const part4 = '080ya/|B2?SE' //saltando 2 letra
  const part5 = 'PbK+G((*--PQes+LiaJazlp|' // rotado al reves 6 primeros
  const part6 = 'e2S3' //solo primos
  const key =
    getPart(part, 1) +
    getPart(part2, 2) +
    getPart(part3, 3) +
    getPart(part4, 4) +
    getPart(part5, 5) +
    getPart(part6, 6)
  return key
}

function getPart(part: string, position: number): string {
  switch (position) {
    case 1:
      return part.substring(0, 6)
    case 2:
      return getString(part, 1)
    case 3:
      return part.substring(12, 18)
    case 4:
      return getString(part, 2)
    case 5:
      return part.substring(0, 6).split('').reverse().join('')
    case 6:
      return part
        .split('')
        .filter((letter, index) => (index + 1) % 2 !== 0)
        .join('')
    case 7:
      return part.substring(6, 12).split('').reverse().join('')
  }
  return part
}

const getString = (text: string, position: number) => {
  const strings = text.split('').filter((letter, index) => {
    const b = position === 1 ? (index + 1) % 2 !== 0 : (index + 1) % 2 === 0
    return b
  })
  return strings.join('')
}

export function encrypt(data: string): string {
  const key = enc.Utf8.parse(ofuscate())
  const srcs = enc.Utf8.parse(data)
  const encrypted = AES.encrypt(srcs, key, { mode: mode.ECB, padding: pad.Pkcs7 })
  return encode(encrypted.toString())
}

export function encode(data: string): string {
  return data.replace(/[!#$%&'()*+,\/:;<=>?@\[\\\]^_`{|}~]/g, (char) => {
    return `%${char.charCodeAt(0).toString(16)}`
  })
}
