export const EDITION_MODE = 0
export const POST_MODE = 1

export const DEEPLINK_TAG = 'DEEPLINK_TAG_'
export const ID_LINK_TAG = '/?ID_LINK_'

export const HASH_PARAM = '&hash='
export const USER_TOKEN_PARAM = 'user-token='

export const EXPORTED_PATH = '/exported'
